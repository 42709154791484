import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Taurus.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Taurus 
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/taurus"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Taurus</h4>
              </a>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Taurus  </h1>
                <h2 className="text-sm md:text-base ml-4">Apr 20 - May 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-love");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-nature");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-man");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-traits");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-facts");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
             
              
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <p className="playfair text-black text-xl md:text-3xl">About Taurus</p><br/>
              <p className="font-bold inline-block text-orange-500">Sanskrit/Vedic name:</p>Vrishabha<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Taurus Meaning:</p>Bull<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Type:</p>Earth Fixed Sign<br/>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Taurus Sign</p><br/>
              Taurus is the second sign of the zodiac and rules the second house. This sign represents a love of worldly pleasures and is all about receiving rewards. One of the most difficult signs is one, also known as the Anchor of the Zodiac. How do you recognize a Taurus? A native American born between April 20th and May 20th belongs to the Sun sign Taurus. Natives are smart, ambitious, and reliable. They have two energy velocities. Some are relaxed and content, while others are excited and ready to attack. You are strong and charming with a sense of confidence and stability. They have a strong and quiet demeanor. They speak in monologues like "Yes, no, thank you, hello" until they fully understand the person. They prefer to move consciously and speak modestly. They are solid and stable and nothing disturbs their peace and tranquility. <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">To know about:</p>Taurus <br/>
              Even if you use all kinds of tactics to confuse them, they won't budge. They are happy and content when they spend time alone. Pushing hard or teasing them makes them angry or stubborn. You are good at controlling your anger. Even impatient Taurus gets angry at times. When they get angry or angry they can destroy everything. They always give in to what they can touch, smell, taste, and appreciate the finer things in life. You are stable, patient, and determined. They live a balanced life of hard work and great success. Their determination gives them stubbornness. They don't stick to the same thing and listen intently to other people's perspectives. They don't change their minds to make other people happy. They are very cautious and don't take many risks.  <br/>
              <br></br>
              Taurus is a passionate zodiac sign and natives want to seek pleasure. Natives make great friends, colleagues, and partners. You value honesty too much. Because of this, their first date resembles a job interview. They ask too many questions and only find out that you are a good fit to start a relationship. will hold a grudge against them, even if it is white lies.<br/>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">All About Taurus</p><br/>
              <p className="font-bold inline-block text-orange-500">Date Range:</p>April 20 - May 20<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Domicile:</p>Venus <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Detriment:</p>Mars and Uranus<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Compatible signs:</p>Cancer, Virgo, Capricorn, Pisces<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Most Compatible Signs:</p>Cancer<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Opposite Signs:</p>Scorpio <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Exaltation:</p>The Moon<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Fall:</p>Uranus<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Element:</p>Earth<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Quality:</p>Fixed<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Symbol:</p>The Bull<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Tarot card:</p>Hierophant<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Fellow signs:</p>Virgo, Capricorn <br/>
              <br></br>
              The Symbol symbolizes Determination and tenacity, fierce power and aggression but also a laid-back, lazy attitude.<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Characteristics:</p>Cold, feminine  <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Noteworthy qualities:</p>Persistence, Steadfastness, trustworthiness, composure, self-reliance, humor, constructiveness, practicality, fearlessness, and stability. <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Undesirable personality traits:</p>Stubbornness, domination, self-centered behavior, laziness, obstruction, dogmatic attitude, conceit, exacting approach.  <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Favorable things:</p>Gardening, romance, branded clothes, cooking, music, working in a team.  <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Things they hate:</p> Sudden changes, possessive, issues, insecurity, synthetic fabrics <br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Birthstone:</p>Diamond, White Topaz, and White Sapphire  <br/>
              <br></br>
              Lucky stones are Emerald, Topaz, Agate<br/>
              Lucky Metals are Silver, gold, copper<br/>
              <br></br>
              The lucky day is Friday<br/>
              <br></br>
              Lucky numbers are 1, 9 <br/>
              <br></br>
              The deepest need of your life is Comfort, material ease, wealth<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Lifetime wish to live :</p>To own the best of everything<br/>
              <br></br>
              <p className="font-bold inline-block text-orange-500">Mantra for your life:</p>Slow and steady<br/>
              <br></br>
              The quality most needed for balance is Flexibility<br/>
              <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
